const FILENAMES_CONTAINER_ID_PREFIX = 'filenamesContainer-';

const generateFileNamesContainerId = (inputName: string) => (
  FILENAMES_CONTAINER_ID_PREFIX + inputName
);

const removeFileNamesContainer = (containerId: string) => {
  const container = document.getElementById(containerId);
  if (container) {
    container.remove();
  }
};

const getFilenameHTMLTemplate = (files: FileList) => {
  const textTemplate = (text: string) => `<span class="text-success">${text}</span>`;
  return `${Array.from(files).map((file) => textTemplate(file.name)).join('')}`;
};

const showFileNamesContainer = (fileInput: HTMLInputElement, containerId: string) => {
  let container = document.getElementById(containerId);

  if (!container) {
    container = document.createElement('div');
    container.id = containerId;
    container.classList.add('d-flex', 'flex-column');
  }

  container.innerHTML = getFilenameHTMLTemplate(fileInput.files!);
  fileInput.after(container);
};

const handleFileInputChange = (e: Event) => {
  const input = e.target as HTMLInputElement;

  if (!input.files) return;

  const fileNamesContainerId = generateFileNamesContainerId(input.name);

  if (input.files.length <= 1) {
    removeFileNamesContainer(fileNamesContainerId);
  } else {
    showFileNamesContainer(input, fileNamesContainerId);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  const fileInputsMultiple = document.querySelectorAll<HTMLInputElement>('input[type="file"][multiple]');
  fileInputsMultiple.forEach((fileInput) => fileInput.addEventListener('change', handleFileInputChange));
});
