import axios from 'axios';
import { App } from '../utils/app';
import { inputFileIsValid } from '../utils/fileValidation';

export const FILE_INPUT_S3_SELECTOR = 'input[type="file"].s3-upload';
const DOCUMENTS_FILE_NAME = 'documents_file';
const FILES_PLACEHOLDER_NAME = 'files_placeholder';
const FILES_PLACEHOLDER_ERROR_CONTAINER_CLASS = 'files-placeholder-error-message';

type InitialMultipartUploadResponseData = {
  part_size: number,
  path: string,
  upload_id: string
};

type PresignedUploadRequestData = {
  requesUrl: string,
  cstfToken: string,
  reqBody: string
};

type PresignedUploadResponseData = {
  url: 'stirng'
};

type S3UploadedPart = {
  partNumber: number,
  ETag: string
};

const getGlobalProjectId = () => document.querySelector<HTMLInputElement>('#projectIdGlobalInput')?.value || '';

const getProjectIdFromForm = (form: HTMLFormElement) => form.querySelector<HTMLInputElement>('input[name="project_id"]')?.value || '';

const getFileFromForm = (form: HTMLFormElement) => {
  const formData = new FormData(form);
  return formData.get(FILES_PLACEHOLDER_NAME) as File;
};

const getFileSizeFromForm = (form: HTMLFormElement) => {
  const file = getFileFromForm(form);
  return file.size;
};

const disablePlaceholderInputInForm = (form: HTMLFormElement) => {
  const input = form.querySelector<HTMLInputElement>(`input[name="${FILES_PLACEHOLDER_NAME}"]`);
  if (input) {
    input.disabled = true;
  }
};

const enablePlaceholderInputInForm = (form: HTMLFormElement) => {
  const input = form.querySelector<HTMLInputElement>(`input[name="${FILES_PLACEHOLDER_NAME}"]`);
  if (input) {
    input.disabled = false;
  }
};

/**
 * Calls backend API to generate the S3 url needed to upload a file part
 * @param form HTMLFormElement - The form that contains the bulk upload
 * @param initializationData - The result of calling initializeMultipartUpload
 * @param partNumber number - The number of the part of the file to be uploaded
 * @returns object - Contains the S3 url to which to send the file part
 */
const getPresignedMultipartData = async (
  form: HTMLFormElement,
  initializationData: InitialMultipartUploadResponseData,
  partNumber: number
) => {
  const formData = new FormData(form);
  const projectId = getProjectIdFromForm(form) || getGlobalProjectId();

  const res = await axios.post<PresignedUploadResponseData>(
    `/projects/${projectId}/bulk-uploads/get-presigned-multipart-upload-url/`,
    {
      filename: initializationData.path,
      upload_id: initializationData.upload_id,
      part_number: partNumber
    },
    {
      headers: {
        'X-CSRFToken': formData.get('csrfmiddlewaretoken')?.toString() || '',
        'Content-Type': 'application/json'
      }
    });

  return res.data;
};

/**
 * Generates the data needed to initialize a multipart upload
 * @param form HTMLFormElement - The form that contains the file to upload
 * @returns object - Contains the csrfToken, url and body of the request to
 *           send through initializeMultipartUpload
 */
const getInitialMultiparUploadData = (form: HTMLFormElement): PresignedUploadRequestData => {
  const formData = new FormData(form);
  const file = getFileFromForm(form);
  const projectId = getProjectIdFromForm(form) || getGlobalProjectId();

  return {
    requesUrl: `/projects/${projectId}/bulk-uploads/init-multipart-upload/`,
    cstfToken: formData.get('csrfmiddlewaretoken')?.toString() || '',
    reqBody: JSON.stringify({ filename: file.name, size: getFileSizeFromForm(form) })
  };
};

/**
 * Generates a chunk of a file extracted from a form
 * @param form HTMLFormElement - The form that contains the file to upload
 * @param start number - The starting index of the chunk of the file to generate
 * @param end number - The end index of the chunk to generate
 * @returns Blob - A Blob containing the chunk of the file
 */
const generateFilePart = (
  form: HTMLFormElement,
  start: number,
  end: number
) => getFileFromForm(form).slice(start, end);

/**
  * Sends request to S3 with the part to upload.
 * @param part Blob - A chunk of the file to upload, generated by generateFilePart.
 * @param url string - The url to S3 to make the request to.
 * @returns AxiosResponse - Contains the header ETag generated by S3 to identify the uploaded part.
 */
const sendPartToS3 = async (part: Blob, url: string) => {
  const res = await axios.put(url, part, { headers: { 'Content-Type': 'multipart/form-data' } });
  return res;
};

/**
 * Gets the data needed to generate a chunk of a file
 * @param iterationIndex number - Index of the current iteration
 * @param partSize number - The part size returned by initializeMultipartUpload
 * @returns object - contains the current partNumber, and the starting and ending indexes to get
 *          the current chunk of the Blob file to upload.
 */
const getCurrentPartInformation = (
  iterationIndex: number,
  partSize: InitialMultipartUploadResponseData['part_size']
) => {
  const start = iterationIndex * partSize;
  return {
    partNumber: iterationIndex + 1,
    partStartIndex: start,
    partEndIndex: start + partSize
  };
};

/**
 * Displays the global progress bar with the current progress of the S3 parts upload
 * @param currentIteration - The current part that is being uploaded
 * @param totalIterations - The total amount of uploads to do
 */
const showS3UploadProgressBar = (currentIteration: number, totalIterations: number) => {
  const progressBarPercentage = (currentIteration * 100) / totalIterations;
  App.Utils.showGlobalProgressBar(progressBarPercentage);
};

/**
 * Sends file to S3 in parts
 * @param form HTMLFormElement - The form that contains the file to upload
 * @param initializationData InitialMultipartUploadResponseData - Result of calling
 *        initializeMultipartUpload
 * @returns S3UploadedPart[] - Contains all uploaded parts with their ids assigned by S3
 */
const sendMultipartUploadToS3 = async (
  form: HTMLFormElement,
  initializationData: InitialMultipartUploadResponseData
) => {
  const sizeOfFileToUpload = getFileSizeFromForm(form);
  const amountOfUploads = Math.ceil(sizeOfFileToUpload / initializationData.part_size);
  const uploadedPartsWithId: S3UploadedPart[] = [];
  /* eslint-disable no-plusplus,  no-await-in-loop */
  for (let index = 0; index < amountOfUploads; index++) {
    const {
      partNumber,
      partEndIndex,
      partStartIndex
    } = getCurrentPartInformation(index, initializationData.part_size);

    const partWithId: S3UploadedPart = {
      partNumber,
      ETag: ''
    };

    showS3UploadProgressBar(index + 1, amountOfUploads);

    const requestData = await getPresignedMultipartData(form, initializationData, partNumber);
    const filePart = generateFilePart(form, partStartIndex, partEndIndex);
    const s3Res = await sendPartToS3(filePart, requestData.url);

    partWithId.ETag = s3Res.headers.etag as string || '';
    uploadedPartsWithId.push(partWithId);
  }
  /* eslint-enable */

  return uploadedPartsWithId;
};

/**
 * Calls api to initialize the multipart upload. Returns the data needed to identify the
 * current upload
 * @param form HTMLFormElement -The form element that contains the upload data
 * @returns InitialMultipartUploadResponseData
 */
const initializeMultipartUpload = async (form: HTMLFormElement) => {
  const requestData = getInitialMultiparUploadData(form);

  const res = await axios.post<InitialMultipartUploadResponseData>(
    requestData.requesUrl,
    requestData.reqBody,
    {
      headers: {
        'X-CSRFToken': requestData.cstfToken,
        'Content-Type': 'application/json'
      }
    });
  return res.data;
};

/**
 * Calls api to finish the multipart upload
 * @param form HTMLFormElement
 * @param uploadedParts S3UploadedPart[] - Array with data of all the file parts uploaded to S3
 * @param initializationData InitialMultipartUploadResponseData
 * @returns res AxiosResponse
 */
const finishPresignedMultipartUpload = async (
  form: HTMLFormElement,
  uploadedParts: S3UploadedPart[],
  initializationData: InitialMultipartUploadResponseData
) => {
  const projectId = getProjectIdFromForm(form) || getGlobalProjectId();
  const formData = new FormData(form);

  const res = await axios.post(
    `/projects/${projectId}/bulk-uploads/finish-presigned-multipart-upload/`,
    {
      filename: initializationData.path,
      parts: uploadedParts.map((part) => ({
        part_number: part.partNumber,
        etag: part.ETag
      })),
      upload_id: initializationData.upload_id
    },
    {
      headers: {
        'X-CSRFToken': formData.get('csrfmiddlewaretoken')?.toString() || '',
        'Content-Type': 'application/json'
      }
    }
  );
  return res;
};

/**
 * Handles all the request to do before submiting the form to backend.
 * TODO: Add section to handle small files that can't be uploaded through multipart endpoints
 * @param e SubmitEvent - The form submit event
 */
const handleBulkUploadSubmit = async (e: SubmitEvent) => {
  e.preventDefault();

  const form = e.target as HTMLFormElement;
  const initializationData = await initializeMultipartUpload(form);
  const presignedUploadData = await sendMultipartUploadToS3(form, initializationData);
  const endUploadResponse = await finishPresignedMultipartUpload(
    form,
    presignedUploadData,
    initializationData
  );

  if (endUploadResponse.statusText === 'OK') {
    const documentsFileInput = form.querySelector<HTMLInputElement>(`input[name="${DOCUMENTS_FILE_NAME}"]`);
    if (documentsFileInput) {
      documentsFileInput.value = initializationData.path;
      // We already sent the file to S3, so we remove the input before submit
      // to avoid sending a duplicated file to backend
      disablePlaceholderInputInForm(form);
      form.submit();
    }
  }
};

const removePlaceholderFieldError = (input: HTMLInputElement) => {
  input.classList.remove('is-invalid');
  document.querySelector(`.${FILES_PLACEHOLDER_ERROR_CONTAINER_CLASS}`)?.remove();
};

const displayPlaceholderFieldError = (input: HTMLInputElement, errorMessage: string) => {
  input.classList.add('is-invalid');
  const messageContainer = document.createElement('span');
  messageContainer.classList.add('text-danger', 'd-inline-block', 'mt-2', FILES_PLACEHOLDER_ERROR_CONTAINER_CLASS);
  messageContainer.textContent = errorMessage;
  input.insertAdjacentElement('afterend', messageContainer);
};

const handlePlacholderFieldInputChange = (e: Event) => {
  const input = e.target as HTMLInputElement;
  const validationResult = inputFileIsValid(input);
  removePlaceholderFieldError(input);

  if (!validationResult.isValid) {
    displayPlaceholderFieldError(input, `Sólo se permiten los formatos ${validationResult.acceptedExtension}`);
  }
};

/**
 * Renders a placeholder input to handle the uploaded file only in frontend and
 * not send it to backend
 * @param form  HTMLFormElement - The form in which the input will be inserted
 */
const handlePlacholderFieldsInForm = (form: HTMLFormElement) => {
  const fileInputs = form.querySelectorAll<HTMLInputElement>(FILE_INPUT_S3_SELECTOR);
  fileInputs.forEach((input) => {
    const placholderFileField = document.createElement('input');
    placholderFileField.type = 'file';
    placholderFileField.name = `${FILES_PLACEHOLDER_NAME}`;
    placholderFileField.classList.add('form-control');
    placholderFileField.required = input.required;
    placholderFileField.id = 'id_files_placeholder';
    placholderFileField.accept = input.accept;

    placholderFileField.addEventListener('change', handlePlacholderFieldInputChange);
    input.replaceWith(placholderFileField);
  });
};

const handlePlacholderFieldsInBulkUploadForm = (form: HTMLFormElement) => {
  const nameInput = form.querySelector<HTMLInputElement>('input[name="name"]');

  if (!nameInput) {
    throw new Error('The field place holder input can only be inserted if there is a name input present');
  }

  const placholderFileField = `
  <div class="row form-row">
    <div class="col-12">
      <div id="files_placeholder_div" class="mb-3">
        <label for="id_files_placeholder" class="form-label">Archivo ZIP con documentos *</label>
        <input type="file" name="${FILES_PLACEHOLDER_NAME}" class="form-control" required id="id_files_placeholder" accept=".zip">
        <small id="files_placeholder_help" class="form-text d-block">Archivo que contiene los documentos a subir</small>
      </div>
    </div>
  </div>
`;

  const nameInputContainer = nameInput.closest('.form-row');

  if (nameInputContainer) {
    nameInputContainer.insertAdjacentHTML('afterend', placholderFileField);
  } else {
    nameInput.insertAdjacentHTML('afterend', placholderFileField);
  }

  // We use type assertion because we are sure that the input exists
  const placeholderInput = form.querySelector(`input[name="${FILES_PLACEHOLDER_NAME}"]`) as HTMLInputElement;
  placeholderInput.addEventListener('change', handlePlacholderFieldInputChange);
};

/**
 * Initializes the upload of files to S3.
 * TODO: the param isBulkUpload is temporal. When backend is available to handle S3 upload
 *       of all input files in the app, then the param shouldn't be needed.
 * @param form - The form that contains the input file to be uploaded to S3
 * @param isBulkUpload - to execute a different script in case the form belongs to bulk uploads
 */
export const handleUploadToS3 = (form: HTMLFormElement, isBulkUpload: boolean) => {
  // TODO: Handle multiple file inputs inside a form
  // TODO: Handle input file multiple
  if (isBulkUpload) {
    handlePlacholderFieldsInBulkUploadForm(form);
  } else {
    handlePlacholderFieldsInForm(form);
  }

  form.addEventListener('submit', (e) => {
    handleBulkUploadSubmit(e)
      .catch((err) => {
        console.error(err);

        App.Utils.showError({
          message: 'There was an error handling the bulk upload',
          alertMessage: 'Ocurrió un error al crear la carga masiva. Inténtelo de nuevo.',
          type: 'danger'
        });
        enablePlaceholderInputInForm(form);
        App.Utils.hideLoading(form);
        App.Utils.hideGlobalProgressBar();
        // TODO: When endpoint is available, add call to api to abort current upload if it fails
      });
  });
};
