import { initChoices } from '../vendors/choices';

export type PropertyTypes = 'text' | 'integer' | 'decimal' | 'date' | 'datetime' | 'boolean' | 'options list';

export interface Option {
  id: number
  name: string
}

function generateBaseInput(id: string, name: string): HTMLInputElement {
  const inputElement = document.createElement('input');
  inputElement.setAttribute('id', id);
  inputElement.setAttribute('name', name);
  inputElement.className = 'form-control mt-0';

  return inputElement;
}

function generateTextInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'text');

  return inputElement;
}

function generateDateInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'date');

  return inputElement;
}

function generateDateTimeInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'datetime-local');

  return inputElement;
}

function generateDecimalInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'number');
  inputElement.setAttribute('step', '0.01');

  return inputElement;
}

function generateIntegerInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'number');
  inputElement.setAttribute('step', '1');

  return inputElement;
}

function generateBooleanInput(id: string, name: string) {
  const inputElement = generateBaseInput(id, name);
  inputElement.setAttribute('type', 'checkbox');
  inputElement.classList.add('form-check-input');

  return inputElement;
}

function generateListOptionsSelect(id: string, name: string, options?: Option[]) {
  const selectElement = document.createElement('select');
  selectElement.setAttribute('name', name);
  selectElement.setAttribute('id', id);
  selectElement.classList.add('form-control', 'mt-0');

  options?.forEach((option) => {
    const optionElement = document.createElement('option');
    optionElement.setAttribute('value', String(option.id));
    optionElement.textContent = option.name;
    selectElement.appendChild(optionElement);
  });

  const choicesManager = initChoices(selectElement);
  const choicesElement = choicesManager.containerOuter.element;

  return choicesElement;
}

const generateInputMap: Record<PropertyTypes, (id: string, name: string, options?: Option[]) =>
HTMLInputElement | HTMLElement
> = {
  text: generateTextInput,
  integer: generateIntegerInput,
  decimal: generateDecimalInput,
  date: generateDateInput,
  datetime: generateDateTimeInput,
  boolean: generateBooleanInput,
  'options list': generateListOptionsSelect
};

export { generateInputMap };
