const handleTableHeaderCheckboxChange = (e: Event) => {
  const headerCheckbox = e.target as HTMLInputElement;
  const tableBodyRowsCheckboxes = headerCheckbox
    .closest('table')?.querySelectorAll<HTMLInputElement>('tbody input[type="checkbox"]');
  tableBodyRowsCheckboxes?.forEach((rowCheckbox) => {
    // eslint-disable-next-line no-param-reassign
    rowCheckbox.checked = headerCheckbox.checked;
  });
};

window.addEventListener('DOMContentLoaded', () => {
  const tableHeaderCheckboxes = document.querySelectorAll<HTMLInputElement>('table th input[type="checkbox"]');
  tableHeaderCheckboxes
    .forEach((checkbox) => checkbox.addEventListener('change', handleTableHeaderCheckboxChange));
});
