import { AlertType, showAlert } from '../behaviors/error-alert';

export interface Error {
  type: Extract<AlertType, 'danger' | 'warning'>;
  message: string;
  alertMessage?: string;
}

export const App = {
  Utils: class Utils {
    static hideLoading(form?: HTMLFormElement) {
      document.body.classList.remove('wait');
      if (!form) return;

      const submitBtn = form.querySelector<HTMLButtonElement>('button[type="submit"]');
      if (!submitBtn) return;

      submitBtn.disabled = false;
      submitBtn.querySelector('.loading-icon')?.remove();
    }

    static thousandSeparator(x: number) {
      return Math.round(x).toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    }

    static showLoading(element: Element) {
      document.body.classList.add('wait');

      if (!element.querySelector('.loading-icon')) {
        element.insertAdjacentHTML(
          'beforeend',
          '<span class="d-inline-block mx-2 loading-icon"><span class="fas fa-spinner fa-spin" aria-hidden="true"></span></span>'
        );
      }
    }

    static highlight(element: Element) {
      element.classList.add('highlight');

      setTimeout(() => {
        element.classList.add('dim');
        element.classList.remove('highlight');
      }, 15);

      setTimeout(() => element.classList.remove('dim'), 1010);
    }

    static showError({
      message,
      type,
      alertMessage
    }: Error) {
      const alertText = alertMessage || message;
      showAlert({ message: alertText, type });
      console.error(message);
    }

    static redirect(url: string) {
      window.location.href = url;
    }

    static hideGlobalProgressBar() {
      const progressBarContainer = document.querySelector<HTMLElement>('#globalProgessBar');
      if (!progressBarContainer) return;

      const progressBar = progressBarContainer.querySelector<HTMLElement>('.progress-bar');
      if (!progressBar) return;

      progressBarContainer.classList.add('invisible');
      progressBar.ariaValueNow = '0';
    }

    static showGlobalProgressBar(progressPercentage: number) {
      const progressBarContainer = document.querySelector<HTMLElement>('#globalProgessBar');
      if (!progressBarContainer) return;

      const progressBar = progressBarContainer.querySelector<HTMLElement>('.progress-bar');
      if (!progressBar) return;

      progressBarContainer.classList.remove('invisible');
      progressBar.style.width = `${progressPercentage}%`;
      progressBar.ariaValueNow = progressPercentage.toString();
    }
  }
};
