// Importing Bootstrap forces the load of the type definition for the window.bootstrap global.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Bootstrap from 'bootstrap';

// Styles
import '../scss/main.scss';

// Vendors
import './vendors/choices';

// Behaviors
import './behaviors/input-rut';
import './behaviors/navbar';
import './behaviors/regions';
import './behaviors/add-property-search-form';
import './behaviors/properties-options-list';
import './behaviors/confirm-report-form';
import './behaviors/generate-report-button';
import './behaviors/document-related-properties';
import './behaviors/table-header-checkbox';
import './behaviors/offcanvas';
import './behaviors/real-number-input';
import './behaviors/input-file-multiple';
import './behaviors/correlative-document-kind';
import './behaviors/collapse-visibility-toggler';
import './behaviors/bulk-upload-job-form';
import './behaviors/mobile-dropdown';
import './behaviors/file-upload';
import './behaviors/table-scroll';

// Utils
import { App } from './utils/app';

window.addEventListener('DOMContentLoaded', () => {
  const alerts = document.querySelectorAll('.alert');
  alerts.forEach((alert) => {
    App.Utils.highlight(alert);
  });

  setTimeout(() => {
    const mainAlerts = document.querySelectorAll('.main-alert .alert');
    mainAlerts.forEach((alert) => {
      window.bootstrap.Alert.getInstance(alert)?.close();
    });
  }, 10000);

  document.querySelectorAll('form')
    .forEach((form) => {
      form.addEventListener('submit', () => {
        const submitButtons = [...form.elements].filter((element) => (
          element.matches('[type="submit"]:not(.js-do-not-disable-on-submit)')
        ));

        // Disable buttons after submit to prevent disabling submit inputs
        // with values
        submitButtons.forEach((submitButton) => {
          // eslint-disable-next-line no-param-reassign
          (submitButton as HTMLButtonElement).disabled = true;
          App.Utils.showLoading(submitButton);
        });

        return true;
      });
    });

  const tooltipTriggerList = document.querySelectorAll<HTMLElement>('[data-bs-toggle="tooltip"]');
  [...tooltipTriggerList]
    .forEach((tooltipTriggerEl) => {
      const isDefaultPlacement = tooltipTriggerEl.dataset.defaultPlacement;
      const tooltipYPlacement = isDefaultPlacement ? 0 : tooltipTriggerEl.clientHeight * -1;
      const tooltipXPlacement = isDefaultPlacement ? 0 : -5;
      // eslint-disable-next-line no-new
      new window.bootstrap.Tooltip(tooltipTriggerEl, {
        offset: [tooltipYPlacement, tooltipXPlacement],
        placement: 'left'
      });
    });
});
