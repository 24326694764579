const GLOBAL_ALERT_CLASS = 'global-alert';

export type AlertType = 'primary' | 'secondary' | 'success' | 'danger' | 'warning' | 'info' | 'light' | 'dark';

export interface Alert {
  type: AlertType;
  message: string;
}

const generateAlert = ({ type, message }: Alert) => (`
<div class="${GLOBAL_ALERT_CLASS} alert alert-${type} fade show" role="alert">
${message}
</div>
`);
export const hideAlerts = () => {
  const allGlobalAlerts = document.querySelectorAll(`.${GLOBAL_ALERT_CLASS}`);
  allGlobalAlerts.forEach((alert) => window.bootstrap.Alert.getOrCreateInstance(alert)?.close());
};

export const showAlert = ({ type, message }: Alert) => {
  const alert = generateAlert({ type, message });
  document.body.insertAdjacentHTML('afterbegin', alert);
  setTimeout(hideAlerts, 10000);
};
