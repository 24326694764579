/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */

import { Item } from 'choices.js';
import { Observable, Observer } from '../types/ObserverPattern';
import { GLOBAL_CHOICES_REFERENCES, ChoicesReference } from '../vendors/choices';

interface SelectOption {
  value: string,
  label: string
}

/**
 *  Generic class to store all options of Choicesjs selectors sucribed to it.
 *  Allows us to handle multiple individual selectors and share the available options for them
 */
class OptionsManager implements Observable {
  public observers: Observer[] = [];
  private baseOptions: SelectOption[] = [];
  private availableOptions: SelectOption[] = [];
  private currentSelectorId: string | undefined = undefined;

  // Initialize ALL possible options for selectors
  setBaseOptions(options: SelectOption[]) {
    this.baseOptions = [...options];
    this.setAvailableOptions(this.baseOptions);
  }

  setCurrentSelectorId(selectorId: string | undefined) {
    this.currentSelectorId = selectorId;
  }

  getCurrentSelectorId(): string | undefined {
    return this.currentSelectorId;
  }

  setAvailableOptions(options: SelectOption[]) {
    this.availableOptions = [...options];
  }

  getAvailableOptions(): SelectOption[] {
    return this.availableOptions;
  }

  /**
   * Takes an array of previously selected options and updates
   * the available options state for all suscribed selectors
   */
  loadOptions(selectedOptions: SelectOption[]) {
    const isSelectedOption = (optionValue: string) => selectedOptions
      .some(({ value: selectedValue }) => optionValue === selectedValue);

    const loadedAvailableOptions = this.baseOptions.filter(({ value }) => !isSelectedOption(value));

    this.setAvailableOptions(loadedAvailableOptions);
    this.notify();
  }

  private reconstructOptionsArray(
    optionsArray: SelectOption[], previousSelection: Item | undefined
  ) {
    if (!previousSelection?.value) {
      return optionsArray;
    }

    // This is the "-------" empty value placeholder
    const emptyValue = optionsArray[0];

    // Reconstructs the new options array setting the empty value at first position
    // Previous value at second position
    // And the rest of the options array
    const reconstructedOptions = [
      emptyValue,
      { value: previousSelection.value as string, label: previousSelection.label },
      ...optionsArray.slice(1)
    ];

    return reconstructedOptions;
  }

  /**
   * Handles the updating proccess for available options
   * Removes the selected option from available options state
   * If an previous selection exists, recatch it and pushes it into the available options state
   */
  updateAvailableOptions(propertyValue: string, propertySelectorId: string) {
    const filteredOptions = this.availableOptions.filter(({ value }) => value !== propertyValue);

    const { choicesRef } = GLOBAL_CHOICES_REFERENCES.find(
      ({ id }) => id === propertySelectorId) as ChoicesReference;

    /**
     * choicesRef._prevState always returns an array of 2 or more positions wich contains
     * latest selections
     * The penultimate position always corresponds to the previous value
     */
    const previousSelection = choicesRef?._prevState.items.at(-2) as Item;

    const updatedOptions = this.reconstructOptionsArray(filteredOptions, previousSelection);

    this.setCurrentSelectorId(propertySelectorId);
    this.setAvailableOptions(updatedOptions);
    this.notify();
  }

  suscribe(selector: Observer) {
    this.observers = [...this.observers, selector];
  }

  notify() {
    this.observers.forEach((selector) => {
      selector.update();
    });

    this.setCurrentSelectorId(undefined);
  }
}

export { OptionsManager };
