const handleTableHorizontalScroll = (tableContainer: HTMLElement) => {
  const table = tableContainer.querySelector<HTMLTableElement>('table:not(.not-scroll-detect)');
  if (!table) return;

  const scrollBarIsVisible = tableContainer.scrollWidth > tableContainer.clientWidth;
  const tableFixedCells = table.querySelectorAll<HTMLTableCellElement>('.table-cell-action:last-child');
  if (scrollBarIsVisible) {
    tableFixedCells.forEach((cell) => {
      cell.classList.add('cell-active');
    });
  } else {
    tableFixedCells.forEach((cell) => {
      cell.classList.remove('cell-active');
    });
  }
};

window.addEventListener('DOMContentLoaded', () => {
  const responsiveTableContainers = document.querySelectorAll<HTMLElement>('.table-responsive');
  responsiveTableContainers.forEach(handleTableHorizontalScroll);

  window.addEventListener('resize', () => {
    responsiveTableContainers.forEach(handleTableHorizontalScroll);
  });
});
