const removeActiveClassFromButtons = (buttons: HTMLButtonElement[]) => {
  buttons.forEach((button) => button.classList.remove('active'));
};

const handleToggleSidebarBtnState = (e: Event) => {
  const button = e.currentTarget as HTMLButtonElement;
  button.classList.toggle('active');
};

window.addEventListener('DOMContentLoaded', () => {
  const toggleSidebarBtns = document.querySelectorAll<HTMLButtonElement>('.toggle-sidebar');
  toggleSidebarBtns.forEach((button) => button.addEventListener('click', (e) => {
    const otherSidebarBtns = Array.from(toggleSidebarBtns).filter((btn) => !btn.isSameNode(button));
    removeActiveClassFromButtons(otherSidebarBtns);
    handleToggleSidebarBtnState(e);
  }));
});
