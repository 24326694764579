const OFFCANVAS_MAX_BREAKPOINT = 992;

const removeActiveClassFromTogglers = () => {
  const offcanvasTogglers = document.querySelectorAll<HTMLElement>('[data-bs-toggle="offcanvas"]');
  offcanvasTogglers.forEach((toggler) => {
    toggler.classList.remove('active');
  });
};

window.addEventListener('DOMContentLoaded', () => {
  /* Bootstrap allows offcanvas visibility by breakpoint after version 5.2.0, so we add a
    quick fix to do it manually for now.
  */
  const offcanvases = document.querySelectorAll<HTMLDivElement>('.offcanvas');

  if (offcanvases.length === 0) { return; }

  window.addEventListener('resize', () => {
    const windowsWidth = window.innerWidth;

    if (windowsWidth > OFFCANVAS_MAX_BREAKPOINT) {
      offcanvases.forEach((offcanvas) => window.bootstrap.Offcanvas.getInstance(offcanvas)?.hide());
      removeActiveClassFromTogglers();
    }
  });
});
