/* eslint-disable @typescript-eslint/no-misused-promises */

import { Item } from 'choices.js';
import { App } from '../utils/app';
import {
  initChoices, GLOBAL_CHOICES_REFERENCES, ChoicesReference, getChoicesReferenceById
} from '../vendors/choices';
import { OptionObserver } from '../utils/OptionsObserver';
import { OptionsManager } from '../utils/OptionsManager';
import { generateInputMap, Option, PropertyTypes } from './generate-dynamic-inputs';

interface PropertyTypeResponse {
  type: PropertyTypes,
  options?: Option[]
}

const FORMSET_COUNTER_ID = 'id_document_property_values-TOTAL_FORMS';
const FORMSET_CLASS = 'formset-div';
const VALUE_DIV_IDENTIFIER = 'value_div';
const INPUTS_NAME_PREFIX_IDENTIFIER = 'document_property_values';
const INPUTS_ID_PREFIX_IDENTIFIER = 'id_document_property_values';
const FILTER_DOCUMENT_FORM_IDENTIFIER = 'filter-documents';
const CHECKBOX_STYLES = ['d-flex', 'flex-row-reverse', 'gap-2', 'justify-content-end', 'align-items-center'];

const optionsManager = new OptionsManager();

async function getPropertyTypeAndOptions(propertyId: string) {
  try {
    const request = await fetch(`/properties/${propertyId}/type-and-options`);
    const data = await request.json() as PropertyTypeResponse;
    return data;
  } catch (err) {
    App.Utils.showError({
      message: 'Property options could not be received',
      alertMessage: 'Error buscando las opciones de la propiedad, por favor inténtelo de nuevo.',
      type: 'danger'
    });
    throw new Error('Property options could not be received');
  }
}

function getAmountOfFormSets() {
  const formSetsAmountHolder = document.getElementById(FORMSET_COUNTER_ID) as HTMLInputElement;
  const currentFormSetsAmount = formSetsAmountHolder?.value || 0;
  return parseInt(currentFormSetsAmount.toString(), 10);
}

function increaseFormSetsCounter() {
  const currentAmount = getAmountOfFormSets();
  const formSetsAmountHolder = document.getElementById(FORMSET_COUNTER_ID) as HTMLInputElement;
  formSetsAmountHolder.value = (currentAmount + 1).toString();
}

function generateNewSelectFromClonedFormSet(clonedFormSet: HTMLDivElement) {
  const formsetAmount = getAmountOfFormSets();
  const clonedSelect = clonedFormSet.querySelector('select');
  const choicesOptions = clonedFormSet.querySelectorAll(`[id*=choices--${clonedSelect?.id || ''}]`);
  const newSelect = document.createElement('select');
  choicesOptions.forEach((option) => {
    if (option.hasAttribute('data-value')) {
      const isNotSelectedOption = optionsManager.getAvailableOptions().some(({ value }) => option.getAttribute('data-value') === value);

      if (isNotSelectedOption) {
        const newOption = document.createElement('option');
        newOption.value = option.getAttribute('data-value') as string;
        newOption.textContent = option.textContent;
        newSelect.append(newOption);
      }
    }
  });

  newSelect.name = `${INPUTS_NAME_PREFIX_IDENTIFIER}-${formsetAmount}-property`;
  newSelect.id = `${INPUTS_ID_PREFIX_IDENTIFIER}-${formsetAmount}-property`;

  return newSelect;
}

function setNewValuesInputAttributesInFormSet(formSet: HTMLDivElement) {
  const formsetAmount = getAmountOfFormSets();
  const propertyValuesInput = formSet.querySelector(`#${INPUTS_ID_PREFIX_IDENTIFIER}-0-value`) as HTMLInputElement;
  const choicesElement = formSet?.querySelector('.choices');

  if (choicesElement) {
    generateDisabledInputFromChoices(formSet, choicesElement);
    return;
  }

  propertyValuesInput.value = '';
  propertyValuesInput.name = `${INPUTS_NAME_PREFIX_IDENTIFIER}-${formsetAmount}-value`;
  propertyValuesInput.id = `${INPUTS_ID_PREFIX_IDENTIFIER}-${formsetAmount}-value`;
  propertyValuesInput.disabled = true;
}

function setNewHiddenInputsAttributesInFormSet(formSet: HTMLDivElement) {
  const formsetAmount = getAmountOfFormSets();

  const propertyValuesIdInput = formSet.querySelector(`#${INPUTS_ID_PREFIX_IDENTIFIER}-0-id`) as HTMLInputElement;
  propertyValuesIdInput.name = `${INPUTS_NAME_PREFIX_IDENTIFIER}-${formsetAmount}-id`;
  propertyValuesIdInput.id = `${INPUTS_ID_PREFIX_IDENTIFIER}-${formsetAmount}-id`;

  const propertyValuesDocumentInput = formSet.querySelector(`#${INPUTS_ID_PREFIX_IDENTIFIER}-0-document`) as HTMLInputElement;
  propertyValuesDocumentInput.name = `${INPUTS_NAME_PREFIX_IDENTIFIER}-${formsetAmount}-document`;
  propertyValuesDocumentInput.id = `${INPUTS_ID_PREFIX_IDENTIFIER}-${formsetAmount}-document`;
}

function generateNewFormSet() {
  const clonedFormSet = document.querySelector(`.${FORMSET_CLASS}`)?.cloneNode(true) as HTMLDivElement;
  const clonedInput = clonedFormSet.querySelector('input.form-control');
  const newSelect = generateNewSelectFromClonedFormSet(clonedFormSet);

  // Remove the cloned select entity
  clonedFormSet.querySelector('.choices')?.remove();

  setNewValuesInputAttributesInFormSet(clonedFormSet);
  setNewHiddenInputsAttributesInFormSet(clonedFormSet);
  // Insert the new select after its related label tag inside the cloned element
  if (clonedInput) {
    generateDisabledInput(clonedInput as HTMLInputElement);
  }

  clonedFormSet.querySelector('[for="id_property"]')?.after(newSelect);
  return clonedFormSet;
}

function initializeNewChoicesSelect(selectElement: HTMLSelectElement) {
  selectElement?.addEventListener('change', handlePropertyChange);
  initChoices(selectElement);

  const selectChoicesReference = GLOBAL_CHOICES_REFERENCES.at(-1) as ChoicesReference;

  // eslint-disable-next-line no-new
  new OptionObserver(selectChoicesReference, optionsManager);
}

function handleNewPropertyBtnClick() {
  const newFormSet = generateNewFormSet();
  const formSetsContainer = document.querySelector('.formsets');
  if (formSetsContainer) {
    formSetsContainer.append(newFormSet);
    increaseFormSetsCounter();
    const newSelect = newFormSet.querySelector('select');
    if (newSelect) initializeNewChoicesSelect(newSelect);
  } else {
    throw new Error('No container to insert the new formset into');
  }
}

function cleanPropertyValueInput(inputElement?: Element | null) {
  if (!inputElement) return;
  inputElement.remove();
}

function generateDisabledInput(inputElement: HTMLInputElement) {
  const container = inputElement?.closest(`#${VALUE_DIV_IDENTIFIER}`);
  container?.classList.remove(...CHECKBOX_STYLES);
  inputElement.setAttribute('value', '');
  inputElement.setAttribute('disabled', 'true');
  inputElement.setAttribute('type', 'text');
  inputElement.classList.remove('form-check-input');
}

function handleSelectedProperty(selectedPropertyValue: string, selectedPropertyId: string) {
  optionsManager.updateAvailableOptions(selectedPropertyValue, selectedPropertyId);
}

async function handlePropertyChange(event: Event) {
  const target = event.target as HTMLSelectElement;
  const selectedPropertyValue = target.value;
  const selectedPropertyId = target.id;

  handleSelectedProperty(selectedPropertyValue, selectedPropertyId);

  const currentFormset = <HTMLDivElement>target.closest(`.${FORMSET_CLASS}`);
  const valueDiv = <HTMLInputElement>currentFormset.querySelector(`#${VALUE_DIV_IDENTIFIER}`);

  // Get input element for value inside current formset
  const inputElement = <HTMLInputElement | HTMLSelectElement>valueDiv.querySelector('.form-control');
  const choicesElement = valueDiv.querySelector('.choices');

  if (!selectedPropertyValue) {
    if (choicesElement) {
      generateDisabledInputFromChoices(currentFormset, choicesElement);
    } else {
      generateDisabledInput(inputElement as HTMLInputElement);
    }

    return;
  }

  const { type, options } = await getPropertyTypeAndOptions(
    selectedPropertyValue
  );

  const newInputElement = generateInputMap[type](inputElement.id, inputElement.name, options);

  if (type === 'boolean') {
    valueDiv.classList.add(...CHECKBOX_STYLES);
  } else {
    valueDiv.classList.remove(...CHECKBOX_STYLES);
  }

  // Always clean old input field and append the new one
  // Corner case if old input field is choices instance
  if (choicesElement) cleanPropertyValueInput(choicesElement);
  cleanPropertyValueInput(valueDiv.querySelector('.form-control'));
  valueDiv.appendChild(newInputElement);
}

function initializedNoValueProperties() {
  const propertiesFormSets = document.querySelectorAll(`.${FORMSET_CLASS}`);
  propertiesFormSets.forEach((formset, index) => {
    const inputElement = <HTMLInputElement>formset.querySelector(`#${INPUTS_ID_PREFIX_IDENTIFIER}-${index}-value`);
    if (!inputElement.value) {
      inputElement.disabled = true;
    }
  });
}

function generateDisabledInputFromChoices(formSet: HTMLDivElement, choicesElement: Element) {
  const formsetAmount = getAmountOfFormSets();
  const valueDiv = <HTMLInputElement>formSet.querySelector(`#${VALUE_DIV_IDENTIFIER}`);

  choicesElement.remove();

  const newDisableInput = <HTMLInputElement>generateInputMap
    .text(`${INPUTS_ID_PREFIX_IDENTIFIER}-${formsetAmount}-value`, `${INPUTS_NAME_PREFIX_IDENTIFIER}-${formsetAmount}-value`);

  newDisableInput.value = '';
  newDisableInput.disabled = true;
  valueDiv.appendChild(newDisableInput);
}

function initializeAddPropertyButton() {
  const addNewPropertyButton = <HTMLButtonElement>document.querySelector('#add_property');
  addNewPropertyButton?.addEventListener('click', handleNewPropertyBtnClick);
}

function initializeFormSetsPropertySelects() {
  const propertiesSelectors: HTMLDivElement[] = Array.from(document.querySelectorAll('.formset-div select'));
  propertiesSelectors.forEach((select) => {
    const choicesReference = getChoicesReferenceById(select.id);

    if (!choicesReference) {
      return;
    }
    // eslint-disable-next-line no-new
    new OptionObserver(choicesReference, optionsManager);

    select.addEventListener('change', handlePropertyChange);
  });
}

function loadMultipleOptionsInManager() {
  const hasMultipleFormsets = getAmountOfFormSets() > 1;

  if (!hasMultipleFormsets) {
    return;
  }

  const propertiesSelectors: HTMLDivElement[] = Array.from(document.querySelectorAll('.formset-div select'));

  const references = propertiesSelectors
    .map((select) => getChoicesReferenceById(select.id)) as ChoicesReference[];

  const selectedOptions = references.map(({ choicesRef }) => {
    if (!choicesRef) {
      throw new Error('Choices ref not found');
    }
    const item = choicesRef.getValue() as Item;

    return {
      value: item.value as string,
      label: item.label
    };
  });

  optionsManager.loadOptions(selectedOptions);
}

// Function needed for set the base options for OptionsManager.
// Ideally this must be an internal proccess of OptionsManager class, but as we are dealing
// with DOM, we need to catch all options from DOM Node selector and format them
// for future handling
function initializePropertiesAvailableOptions() {
  const basePropertySelectorId = 'id_document_property_values-0-property';

  const { baseSelect } = getChoicesReferenceById(basePropertySelectorId) as ChoicesReference;

  const baseOptions: HTMLOptionElement[] = Array.from(baseSelect.querySelectorAll('option'));

  const formattedBaseOptions = baseOptions.map(({ value, textContent }) => ({
    value,
    label: textContent ?? ''
  }));

  // Always, at initialization, set base options for optionsManager instance
  optionsManager.setBaseOptions(formattedBaseOptions);

  loadMultipleOptionsInManager();
}

function initializeDocumentsListPage() {
  const searchDocumentsForm = <HTMLFormElement>document.querySelector(`#${FILTER_DOCUMENT_FORM_IDENTIFIER}`);

  if (!searchDocumentsForm) return;

  initializeFormSetsPropertySelects();
  initializePropertiesAvailableOptions();
  initializedNoValueProperties();
  initializeAddPropertyButton();
}

window.addEventListener('DOMContentLoaded', () => {
  initializeDocumentsListPage();
});
