/* eslint-disable no-underscore-dangle */
// eslint-disable-next-line max-classes-per-file
import { Item } from 'choices.js';
import { OptionsManager } from './OptionsManager';
import { Observer } from '../types/ObserverPattern';
import { ChoicesReference } from '../vendors/choices';

/**
 * Generic class for suscribing to a OptionManager handler for automatic options updating
 */
class OptionObserver implements Observer {
  constructor(private choicesReference: ChoicesReference, private optionsManager: OptionsManager) {
    this.optionsManager.suscribe(this);
  }

  update() {
    const { choicesRef, id } = this.choicesReference;
    const currentChoiceItem = choicesRef?.getValue() as Item;

    // If the current updating observer its the same selector whose dispatch the updating, doesnt
    // removes the option from select
    if (id === this.optionsManager.getCurrentSelectorId()) {
      return;
    }

    if (!choicesRef) {
      throw new Error('Choices is not defined for this OptionObserver');
    }
    choicesRef.clearStore();
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    choicesRef.setChoices(this.optionsManager.getAvailableOptions());
    choicesRef.setChoiceByValue(currentChoiceItem.value as string);
    choicesRef.setValue([currentChoiceItem]);
  }
}

export { OptionObserver };
