import { App } from '../utils/app';

const REPORT_FORM_SELECTOR = '.confirm-report-form';

const triggerFileDownload = async (response: Response) => {
  const filename = response.headers.get('Content-Disposition')?.split('=')[1];
  if (!filename) return;
  const blob = await response.blob();
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = filename;
  link.target = '_blank';
  link.dispatchEvent(new MouseEvent('click'));
};

const redirectAfterSubmit = () => {
  const form = document.querySelector<HTMLFormElement>(REPORT_FORM_SELECTOR);
  const redirectUrl = form?.dataset.redirectUrl;
  if (!form || !redirectUrl) return;

  App.Utils.redirect(redirectUrl);
};

const sendReportFormData = async (data: FormData) => {
  const path = window.location.href;
  const res = await fetch(path, {
    method: 'POST',
    body: data
  });
  /* This validation is to handle backend response when download fails due to user not having
    selected a document. Normally it should never be true,
    but we leave it as an emergency edge case.
  */
  if (res.redirected) {
    App.Utils.redirect(res.url);
  } else {
    await triggerFileDownload(res);
    redirectAfterSubmit();
  }
};

const handleConfirmReportFormSubmit = (e: SubmitEvent) => {
  e.preventDefault();
  const formData = new FormData(e.target as HTMLFormElement);
  sendReportFormData(formData)
    .catch((err) => {
      App.Utils.showError({
        message: err as string,
        type: 'danger',
        alertMessage: 'Ocurrió un error descargando el reporte. Por favor inténtelo de nuevo.'
      });
    }).finally(() => {
      App.Utils.hideLoading();
    });
};

window.addEventListener('DOMContentLoaded', () => {
  const confirmReportForm = document.querySelector<HTMLFormElement>(REPORT_FORM_SELECTOR);
  if (confirmReportForm) {
    // Listener to redirect to document list when report download starts
    confirmReportForm.addEventListener('submit', handleConfirmReportFormSubmit);
  }
});
