import Choices from 'choices.js';
import { getChoicesReferenceById } from '../vendors/choices';

const getDocumentTypeChoicesRef = (): Choices | null => {
  const select = document.querySelector<HTMLSelectElement>('#id_document_type');
  if (!select) return null;

  const choicesRef = getChoicesReferenceById(select.id);
  if (!choicesRef) return null;

  return choicesRef.choicesRef;
};

const resetDocumentTypeSelect = () => {
  const select = getDocumentTypeChoicesRef();
  if (!select) return;

  select.removeActiveItems(-1);
};

const getDocumentTypeContainer = () => document.querySelector<HTMLDivElement>('#document_type_div');

const hideDocumentTypeContainer = () => {
  const container = getDocumentTypeContainer();
  if (!container) return;

  container.classList.add('d-none');
  resetDocumentTypeSelect();
};

const showDocumentTypeContainer = () => {
  const container = getDocumentTypeContainer();
  if (!container) return;

  container.classList.remove('d-none');
};

const handleCorrelativeDocumentKindValue = (value: string) => {
  if (value === 'outgoing document') {
    showDocumentTypeContainer();
  } else {
    hideDocumentTypeContainer();
  }
};

const initCorrelativeDocumentKind = (select: HTMLSelectElement) => {
  handleCorrelativeDocumentKindValue(select.value);
  select.addEventListener('change', (e: Event) => {
    const eventSelect = e.target as HTMLSelectElement;
    handleCorrelativeDocumentKindValue(eventSelect.value);
  });
};

window.addEventListener('DOMContentLoaded', () => {
  const correlativeForm = document.querySelector<HTMLFormElement>('#correlativeForm');
  const documentKindSelect = document.querySelector<HTMLSelectElement>('#id_document_kind');

  if (!correlativeForm || !documentKindSelect) return;
  initCorrelativeDocumentKind(documentKindSelect);
});
