import Choices from 'choices.js';
import { getChoicesReferenceById, initChoices } from '../vendors/choices';

type ChoiceOption = {
  label: string,
  value: string
};

// TODO: Change base selector to use data attribute instead of id
const PROPERTY_SELECT_ID_BASE_SELECTOR = '#id_property_';

const getSelectById = (id: string) => document.querySelector<HTMLSelectElement>(`${PROPERTY_SELECT_ID_BASE_SELECTOR}${id}`);

const insertOptionsToChoices = (options: ChoiceOption[], choices: Choices) => {
  const placeholderValue = ' ';
  const placeholder: ChoiceOption = {
    value: placeholderValue,
    label: '---------'
  };

  choices.setValue([placeholder, ...options]);
  choices.setChoiceByValue(placeholderValue);
};

const triggerRelatedParentSelectChange = (relatedSelectId: string, selectedOptionValue: string) => {
  const selectToChange = getSelectById(relatedSelectId);
  if (!selectToChange) return;
  const choicesToChangeRef = getChoicesReferenceById(selectToChange.id);

  if (!choicesToChangeRef || !choicesToChangeRef.choicesRef) {
    console.error(`Choices ref doesn't exists for select with id ${selectToChange.id}`);
  } else {
    choicesToChangeRef.choicesRef.setChoiceByValue(selectedOptionValue);
  }
};

const handleChildrenRelatedSelectChange = (e: Event) => {
  const target = e.target as HTMLSelectElement;
  const relatedChildrenSelectId = target.dataset.relatedSelect;
  if (!relatedChildrenSelectId) return;

  const baseSelectRef = getChoicesReferenceById(target.id);
  const optionSelected = baseSelectRef?.baseSelect.querySelector<HTMLOptionElement>(`option[value="${target.value}"]`);
  if (!optionSelected) return;

  const relatedOptionId = optionSelected.dataset.relatedOption || '';
  triggerRelatedParentSelectChange(relatedChildrenSelectId, relatedOptionId);
};

const showAllRelatedChildrenSelectOptions = (select: HTMLSelectElement) => {
  const selectChoicesRef = getChoicesReferenceById(select.id);
  if (!selectChoicesRef || !selectChoicesRef.choicesRef) return;
  selectChoicesRef.choicesRef.clearChoices();
  const optionsToShow = [...selectChoicesRef.baseSelect.options].map((option) => ({
    value: option.value,
    label: option.textContent || ''
  }));
  insertOptionsToChoices(optionsToShow, selectChoicesRef.choicesRef);
};

const showRelatedChildrenOptions = (select: HTMLSelectElement, optionsId: string) => {
  const selectChoicesRef = getChoicesReferenceById(select.id);
  if (!selectChoicesRef || !selectChoicesRef.choicesRef) return;
  const filteredOptions = [...selectChoicesRef.baseSelect.options]
    .filter((option) => option.dataset.relatedOption === optionsId);

  selectChoicesRef.choicesRef.clearChoices();
  const optionsToShow = filteredOptions.map((option) => ({
    value: option.value,
    label: option.textContent || ''
  }));
  insertOptionsToChoices(optionsToShow, selectChoicesRef.choicesRef);
};

const triggerRelatedChildrenSelectChange = (
  childrenSelectId: string,
  selectedOptionValue: string
) => {
  const relatedChildrenSelect = getSelectById(childrenSelectId);
  if (!relatedChildrenSelect) return;

  if (!selectedOptionValue) {
    showAllRelatedChildrenSelectOptions(relatedChildrenSelect);
  } else {
    showRelatedChildrenOptions(relatedChildrenSelect, selectedOptionValue);
  }
};

// Parent selects might have multiple children ids separated by a colon.
const getChildrenIdsFromParentData = (relatedSelectsData: string) => relatedSelectsData.split(',');

const handleParentRelatedSelectChange = (e: Event) => {
  const target = e.target as HTMLSelectElement;
  if (!target.dataset.relatedSelects) return;
  const relatedParentSelectIds = getChildrenIdsFromParentData(target.dataset.relatedSelects);
  const valueSelected = target.value;
  relatedParentSelectIds
    .forEach((id) => triggerRelatedChildrenSelectChange(id, valueSelected));
};

const initRelatedParentSelects = () => {
  const relatedParentSelects = document.querySelectorAll<HTMLSelectElement>('select[data-related-selects]');
  relatedParentSelects.forEach((select) => {
    initChoices(select);
    select.addEventListener('change', handleParentRelatedSelectChange);
  });
};

const initRelatedChildrenSelects = () => {
  const relatedChildrenSelects = document.querySelectorAll<HTMLSelectElement>('select[data-related-select]');
  relatedChildrenSelects.forEach((select) => {
    initChoices(select);
    select.addEventListener('change', handleChildrenRelatedSelectChange);
  });
};

const initDocumentRelatedSelects = () => {
  /* There are two types of related selects:
    - Parents: might have multiple children selects related
    - Children: might have one paren select related
  */
  initRelatedParentSelects();
  initRelatedChildrenSelects();
};

window.addEventListener('DOMContentLoaded', () => {
  const documentForm = document.querySelector('form.document-form');

  if (documentForm) {
    initDocumentRelatedSelects();
  }
});
