import { FILE_INPUT_S3_SELECTOR, handleUploadToS3 } from './bulk-upload-job-form';

window.addEventListener('DOMContentLoaded', () => {
  /* TODO: bulkUploadJobForm is a temp variable to force the script to be executed only in the for
  bulkUploadJobForm because there is not backend available to support the s3 upload in all
  input fields. When the backend is finished, then the  bulkUploadJobForm and the validation
  should be removed */
  const bulkUploadJobForm = document.querySelector<HTMLFormElement>('#bulkUploadJobForm:not(.not-s3)');
  if (bulkUploadJobForm) {
    handleUploadToS3(bulkUploadJobForm, true);
  } else {
    const fileInput = document.querySelector<HTMLInputElement>(FILE_INPUT_S3_SELECTOR);
    if (!fileInput) return;

    // If there is at least one file input, we upload its contents to S3
    const form = fileInput.closest('form');
    if (form) {
      handleUploadToS3(form, false);
    }
  }
});
