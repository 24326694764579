export type InputFileValidationState = {
  isValid: boolean,
  acceptedExtension: string
};

/**
 * Checks wether the file uploaded in the file input is valid
 * @param input - HTMLInputElement
 * @returns InputFileValidationState - The state of the validation
 */
export function inputFileIsValid(input: HTMLInputElement): InputFileValidationState {
  const acceptValue = input.getAttribute('accept');
  const result: InputFileValidationState = {
    isValid: true,
    acceptedExtension: ''
  };

  if (!acceptValue) return result;
  result.acceptedExtension = acceptValue;

  const { files } = input;
  if (!files) {
    result.isValid = false;
    return result;
  }

  const acceptedExtensions = acceptValue.split(',').map((val) => val.trim());

  result.isValid = Array.from(files).every((file) => {
    const { name } = file;
    const fileExtension = name.split('.').pop() || '';
    return acceptedExtensions.includes(`.${fileExtension}`);
  });

  return result;
}
