import { App } from '../utils/app';
import { GLOBAL_CHOICES_REFERENCES, initChoices } from '../vendors/choices';

// PROPERTY GENERAL INFO SELECTORS
const FORM_SELECTOR = '.property-form';
const ROW_SELECTOR = '.row';
const PROPERTY_TYPE_SELECTOR = '#id_type';
const IS_MULTIVALUED_SELECTOR = '#is_multivalued_div';
const FILTER_PROPERTY_DIV_SELECTOR = '#filter_property_div';
const FILTER_PROPERTY_SELECT = '#id_filter_property';
const VISIBLE_FORMSETS_AMOUNT_INPUT_ID = 'form_sets_visibile_amount';
// FORMSET SELECTORS
const FORMSET_SELECTOR = '.formset';
const FORMSET_CONTENT_SELECTOR = '#formset-content';
const FORMSET_DIV_SELECTOR = '.formset-div';

// ADD OPTION SELECTORS
const ADD_OPTION_BUTTON = '#add_option';
const OPTION_INPUT_SELECTOR = '#id_options';
const FORMSET_TOTAL_SELECTOR = '#id_options-TOTAL_FORMS';
const FORMSET_MIN_AMOUNT_SELECTOR = '#id_options-MIN_NUM_FORMS';

// REMOVE OPTION SELECTORS
const REMOVE_BUTTON_CONTAINER_CLASS = 'remove-btn-container';
const DYNAMICALLY_GENERATED_FORMSET_CLASS = 'dynamic-formset';

//  ADD RELATED OPTIONS TO SELECT
const RELATED_OPTIONS_SELECTOR = '.form-control.related_option_select';
const RELATED_OPTIONS_DIV_SELECTOR = '#related_option_div';

// Get OPTION LIST associated elements which must be hidden or shown simultaneously
const LIST_OPTION_ELEMENTS: (Element | null | undefined)[] = [
  document.querySelector(FORMSET_SELECTOR),
  document.querySelector(FILTER_PROPERTY_DIV_SELECTOR),
  document.querySelector(IS_MULTIVALUED_SELECTOR)?.closest(ROW_SELECTOR)
];

interface CurrentSelectOption {
  value: string;
  label: string;
}
let currentOptions: CurrentSelectOption[] = [];

// FUNCTIONS
const generateOptionInputId = (keyword: string, id: string | number = 0) => `${OPTION_INPUT_SELECTOR}-${id}-${keyword}`;

const generateRelatedOptionsEndpoint = (value: string) => `/properties/${value}/options`;

const getRelatedPropertiesOptions = async (value: string) => {
  try {
    const response = await fetch(generateRelatedOptionsEndpoint(value));
    const optionsList = await response.json() as { id: string, name: string }[];

    const emptyOption = {
      value: ' ',
      label: '---------'
    };

    // This mapping is neccessary for future Choices.js implementation
    const formattedOptions = optionsList.map(({ id, name }) => ({
      value: id,
      label: name
    }));

    return [emptyOption, ...formattedOptions];
  } catch (error) {
    throw new Error('Error getting related properties options');
  }
};

const getAmountOfFormSets = () => {
  const amountInput = document.querySelector<HTMLInputElement>(FORMSET_TOTAL_SELECTOR);
  return amountInput ? Number(amountInput.value) : 0;
};

const reduceAmountOfFormSetsByOne = () => {
  const amountInput = document.querySelector<HTMLInputElement>(FORMSET_TOTAL_SELECTOR);
  const currentAmount = getAmountOfFormSets();
  if (amountInput) {
    amountInput.value = (currentAmount - 1).toString();
  }
};

const getMinimumAmountOfFormSets = () => {
  const minAmountInput = document.querySelector<HTMLInputElement>(FORMSET_MIN_AMOUNT_SELECTOR);
  return minAmountInput ? Number(minAmountInput.value) : 0;
};

const hideElements = (elementsToHide: (Element | null | undefined)[]) => {
  elementsToHide.forEach((element) => {
    element?.classList.add('d-none');
  });
};

const showElements = (elementsToShow: (Element | null | undefined)[]) => {
  elementsToShow?.forEach((element) => {
    element?.classList.remove('d-none');
  });
};

const isOptionListProperty = (property: string) => property === 'options list';

const toggleListOptions = (value: string) => {
  if (isOptionListProperty(value)) {
    showElements(LIST_OPTION_ELEMENTS);
    showElements([...document.querySelectorAll(ADD_OPTION_BUTTON)]);
    return;
  }
  hideElements(LIST_OPTION_ELEMENTS);
  hideElements([...document.querySelectorAll(ADD_OPTION_BUTTON)]);
};

const saveNewGlobalCurrentFilterOptions = (options: CurrentSelectOption[]) => {
  currentOptions = [...options];
};

const createOptions = async (
  node: HTMLSelectElement,
  availableOptions: CurrentSelectOption[]
) => {
  const nodeId = node.id;
  const nodeChoicesRef = GLOBAL_CHOICES_REFERENCES.find((ref) => ref.id === nodeId);
  nodeChoicesRef?.choicesRef?.clearChoices();
  await nodeChoicesRef?.choicesRef?.setChoices(availableOptions);
  nodeChoicesRef?.choicesRef?.removeActiveItems(-1);
  saveNewGlobalCurrentFilterOptions(availableOptions);
};

const cleanOptions = (node: HTMLSelectElement) => {
  const optionsAmount = node.options.length;

  for (let i = optionsAmount; i >= 0; i -= 1) {
    node.options.remove(i);
  }
};

const appendOptionsToSelect = async (value: string) => {
  const relatedPropertiesSelect: NodeListOf<HTMLSelectElement> = document
    .querySelectorAll(RELATED_OPTIONS_SELECTOR);
  // Catch hell this because choices forces the usage of promises when adding options dynamically
  try {
    const availableOptions = await getRelatedPropertiesOptions(value);
    if (availableOptions?.length) {
      relatedPropertiesSelect.forEach((node) => {
        cleanOptions(node);
        createOptions(node, availableOptions)
          .catch(() => {
            throw new Error('Error while handling select options');
          });
      });
    }
  } catch (err) {
    relatedPropertiesSelect.forEach((node) => {
      cleanOptions(node);
    });
  }
};

function createAndAppendRelatedOptionSelect(selectRef: HTMLInputElement | HTMLSelectElement) {
  const newSelect = document.createElement('select');
  newSelect.name = selectRef.name;
  newSelect.className = selectRef.className;
  newSelect.id = selectRef.id;
  newSelect.removeAttribute('data-choice');
  newSelect.classList.remove('choices__input');
  currentOptions.forEach((optionData) => {
    const option = document.createElement('option');
    option.value = optionData.value;
    option.textContent = optionData.label;
    newSelect.append(option);
  });
  selectRef.closest('.choices')?.replaceWith(newSelect);
  initChoices(newSelect);
}

const getNewOptionClonedNode = () => {
  const allCurrentOptionDivs = document.querySelectorAll<HTMLDivElement>(FORMSET_DIV_SELECTOR);
  // Cloning the last option div because is the only one 100% guaranteed to have the delete checkbox
  const optionDivToClone = allCurrentOptionDivs[allCurrentOptionDivs.length - 1];
  return optionDivToClone.cloneNode(true) as HTMLDivElement;
};

const getLastFormsetIdNumber = () => {
  const formSets = document.querySelectorAll(FORMSET_DIV_SELECTOR);
  const lastFormSet = formSets[formSets.length - 1];
  const input = lastFormSet.querySelector('input[name$="name"]');
  if (!lastFormSet || !input) return 0;

  const inputId = input.id;
  const formsetIdNumber = inputId.match(/\d/g);
  return Number(formsetIdNumber?.join(''));
};

const hideAllOptionDeleteButtons = () => {
  const formSetContainers = document.querySelectorAll(FORMSET_DIV_SELECTOR);
  formSetContainers.forEach((container) => {
    const buttonContainer = container.querySelector(`.${REMOVE_BUTTON_CONTAINER_CLASS}`);
    hideElements([buttonContainer]);
  });
};

const showAllOptionDeleteButtons = () => {
  const formSetContainers = document.querySelectorAll(FORMSET_DIV_SELECTOR);
  formSetContainers.forEach((container) => {
    const buttonContainer = container.querySelector(`.${REMOVE_BUTTON_CONTAINER_CLASS}`);
    showElements([buttonContainer]);
  });
};

const getAmountOfVisibleFormSets = () => {
  const visibleFormSetsInput = document.querySelector<HTMLInputElement>(`#${VISIBLE_FORMSETS_AMOUNT_INPUT_ID}`);
  return Number(visibleFormSetsInput?.value) || 0;
};

const increaseAmountOfVisibleFormSetsByOne = () => {
  const visibleFormSetsInput = document.querySelector<HTMLInputElement>(`#${VISIBLE_FORMSETS_AMOUNT_INPUT_ID}`);
  const currentAmount = getAmountOfVisibleFormSets();

  if (visibleFormSetsInput) {
    visibleFormSetsInput.value = (currentAmount + 1).toString();
  }
};

const reduceAmountOfVisibleFormSetsByOne = () => {
  const visibleFormSetsInput = document.querySelector<HTMLInputElement>(`#${VISIBLE_FORMSETS_AMOUNT_INPUT_ID}`);
  const currentAmount = getAmountOfVisibleFormSets();

  if (visibleFormSetsInput) {
    visibleFormSetsInput.value = (currentAmount - 1).toString();
  }
};

const handleAllRemoveButtonsVisibility = () => {
  const visibleFormSetsAmount = getAmountOfVisibleFormSets();
  const minAmountOfFormSets = getMinimumAmountOfFormSets();
  if (visibleFormSetsAmount <= minAmountOfFormSets) {
    hideAllOptionDeleteButtons();
  } else {
    showAllOptionDeleteButtons();
  }
};

const deleteOptionButtonClick = (checkboxContainer: HTMLDivElement) => {
  const checkbox = checkboxContainer.querySelector<HTMLInputElement>('input[type="checkbox"]');
  if (!checkbox) return;

  const optionContainer = checkbox.closest(FORMSET_DIV_SELECTOR);
  const wasDynamicallyGenerated = optionContainer?.classList
    .contains(DYNAMICALLY_GENERATED_FORMSET_CLASS);

  if (wasDynamicallyGenerated) {
    optionContainer?.remove();
    reduceAmountOfFormSetsByOne();
  } else {
    checkbox.checked = true;
    hideElements([optionContainer]);
  }

  reduceAmountOfVisibleFormSetsByOne();
  handleAllRemoveButtonsVisibility();
};

const getPropertyInputInNewOptionNode = (propertyToGet: string, newOptionNode: HTMLDivElement) => {
  const newOptionQuerySelector = generateOptionInputId(propertyToGet, getLastFormsetIdNumber());
  return <HTMLInputElement | HTMLSelectElement>newOptionNode.querySelector(newOptionQuerySelector);
};

const setNewOptionInputAttributes = (input: HTMLInputElement | HTMLSelectElement, id: string, name: string, value = '') => {
  input.setAttribute('id', id);
  input.setAttribute('name', name);
  // eslint-disable-next-line no-param-reassign
  input.value = value;
};

const addOption = () => {
  const OPTION_INPUT_PROPERTIES = ['name', 'related_property', 'id', 'related_option', 'DELETE'];
  const formsetContainer = <HTMLDivElement>document.querySelector(FORMSET_CONTENT_SELECTOR);
  const fieldsetsCounterElement = <HTMLInputElement>document.querySelector(FORMSET_TOTAL_SELECTOR);
  const numberOfFieldsets = getLastFormsetIdNumber() + 1;
  const newOption = getNewOptionClonedNode();
  /**
   * Iterate over formset input fields keywords and generate their ID and NAME based
   * on the number of current fieldsets
   * */
  OPTION_INPUT_PROPERTIES.forEach((property) => {
    const newOptionInputFieldSet = getPropertyInputInNewOptionNode(property, newOption);
    const newOptionId = generateOptionInputId(property, numberOfFieldsets).slice(1);
    const newOptionName = generateOptionInputId(property, numberOfFieldsets).slice(4);
    if (newOptionInputFieldSet) {
      const choicesRef = GLOBAL_CHOICES_REFERENCES
        .find((ref) => ref.id === newOptionInputFieldSet.id);
      setNewOptionInputAttributes(newOptionInputFieldSet, newOptionId, newOptionName);

      // When property is equal to related_option, optionInputFieldset references to SELECT Element
      if (property === 'related_option' && choicesRef) {
        createAndAppendRelatedOptionSelect(newOptionInputFieldSet);
      }

      if (property.toLocaleLowerCase() === 'delete') {
        const deleteButtonContainer = newOptionInputFieldSet.closest<HTMLDivElement>(`.${REMOVE_BUTTON_CONTAINER_CLASS}`);
        const deleteOptionButton = deleteButtonContainer?.querySelector<HTMLButtonElement>('button');
        if (deleteButtonContainer && deleteOptionButton) {
          deleteOptionButton?.addEventListener('click', () => deleteOptionButtonClick(deleteButtonContainer));
        }
      }
    }
  });
  fieldsetsCounterElement.value = String(numberOfFieldsets + 1);
  newOption.classList.remove('d-none');
  /* We need to differentiate between dynamic and not dynamic formsets due to how django handles
     the formset deletion
  */
  newOption.classList.add(DYNAMICALLY_GENERATED_FORMSET_CLASS);
  if (formsetContainer) {
    formsetContainer.appendChild(newOption);
    increaseAmountOfVisibleFormSetsByOne();
    handleAllRemoveButtonsVisibility();
  }
};

/*
  Initializes selects with Choices js. Is done here to help us handle any problem
  that may arise before initialization of dynamic fields
*/
const initializeBaseChoiceInstance = () => {
  const select = document.querySelector<HTMLSelectElement>(RELATED_OPTIONS_SELECTOR);
  if (select) {
    initChoices(select);
  }
};

const hideAllRelatedPropertiesRows = () => {
  const relatedPropertiesSelect: NodeListOf<HTMLSelectElement> = document
    .querySelectorAll(RELATED_OPTIONS_DIV_SELECTOR);

  relatedPropertiesSelect.forEach((node) => {
    const selectDiv = <HTMLDivElement>node.closest(ROW_SELECTOR);
    hideElements([selectDiv]);
  });
};

const createCurrentVisibleFormSetsHiddenInput = () => {
  const input = document.createElement('input');
  input.type = 'hidden';
  input.value = getAmountOfFormSets().toString();
  input.id = VISIBLE_FORMSETS_AMOUNT_INPUT_ID;
  document.body.appendChild(input);
};

const initializeHiddenElements = () => {
  hideAllRelatedPropertiesRows();
  hideElements(LIST_OPTION_ELEMENTS);
  hideElements([...document.querySelectorAll(ADD_OPTION_BUTTON)]);
};

const getCurrentPropertyTypeValue = () => {
  const propertyTypeSelectElement = <HTMLSelectElement>document
    .querySelector(PROPERTY_TYPE_SELECTOR);
  return propertyTypeSelectElement.value || '';
};

const initializePropertyTypeSelect = () => {
  const propertyTypeSelectElement = <HTMLSelectElement>document
    .querySelector(PROPERTY_TYPE_SELECTOR);

  propertyTypeSelectElement?.addEventListener('change', (event) => {
    const selectedOption = (<HTMLSelectElement>event.target).value;

    toggleListOptions(selectedOption);
  });
};

const initializeAddOptionButton = () => {
  const addOptionButtonElement = <HTMLButtonElement>document.querySelector(ADD_OPTION_BUTTON);
  addOptionButtonElement?.addEventListener('click', addOption);
};

const handleFilterPropertySelectChange = (event: Event) => {
  const selectedOption = (<HTMLSelectElement>event.target).value;
  const relatedPropertiesSelectContainer: NodeListOf<HTMLDivElement> = document
    .querySelectorAll(RELATED_OPTIONS_DIV_SELECTOR);

  if (selectedOption) {
    relatedPropertiesSelectContainer.forEach((node) => {
      const selectDiv = <HTMLDivElement>node.closest(ROW_SELECTOR);
      showElements([selectDiv]);
    });

    appendOptionsToSelect(selectedOption)
      .catch((e) => {
        App.Utils.showError({
          message: e as string,
          type: 'danger',
          alertMessage: 'Ocurrió un error, por favor inténtelo nuevamente.'
        });
      });
  } else {
    relatedPropertiesSelectContainer.forEach((node) => {
      const selectDiv = <HTMLDivElement>node.closest(ROW_SELECTOR);
      hideElements([selectDiv]);
    });
  }
};

const initializeFilterRelatedPropertySelect = () => {
  const filterPropertyElement = <HTMLSelectElement>document.querySelector(FILTER_PROPERTY_SELECT);
  filterPropertyElement?.addEventListener('change', handleFilterPropertySelectChange);
};

const hideRemoveCheckboxContainerChilds = (checkboxContainer: HTMLDivElement) => {
  const label = checkboxContainer.querySelector('label.form-check-label');
  const checkbox = checkboxContainer.querySelector<HTMLInputElement>('input[type="checkbox"]');
  hideElements([label, checkbox]);
};

const changeRemoveCheckboxToButton = (checkboxContainer: HTMLDivElement) => {
  hideRemoveCheckboxContainerChilds(checkboxContainer);
  checkboxContainer.classList.remove('form-check');
  checkboxContainer.classList.add(REMOVE_BUTTON_CONTAINER_CLASS);
  const button = document.createElement('button');
  button.textContent = 'Eliminar opción';
  button.type = 'button';
  button.classList.add('btn', 'btn-sm', 'btn-link', 'p-0', 'text-secondary');
  button.addEventListener('click', () => deleteOptionButtonClick(checkboxContainer));
  checkboxContainer.appendChild(button);
};

const initializeRemoveOptionButton = () => {
  // TODO: Change selector to use a custom class and not .form-check
  const deleteOptionCheckboxContainers = document.querySelectorAll<HTMLDivElement>(`${FORMSET_CONTENT_SELECTOR} .form-check`);
  const minAmountOfFormSets = getMinimumAmountOfFormSets();
  if (deleteOptionCheckboxContainers.length > minAmountOfFormSets) {
    deleteOptionCheckboxContainers.forEach(changeRemoveCheckboxToButton);
  } else {
    hideElements([...deleteOptionCheckboxContainers]);
  }
};

const getCurrentFilterPropertyValue = () => {
  const filterPropertyElement = <HTMLSelectElement>document.querySelector(FILTER_PROPERTY_SELECT);
  return filterPropertyElement.value || '';
};

const setInitialFilterRelatedPropertyOptions = async () => {
  const currentFilterProperty = getCurrentFilterPropertyValue();
  if (currentFilterProperty) {
    const filterOptions = await getRelatedPropertiesOptions(currentFilterProperty);
    saveNewGlobalCurrentFilterOptions(filterOptions);
  }
};

window.addEventListener('DOMContentLoaded', () => {
  const propertyForm = document.querySelector(`form${FORM_SELECTOR}`);
  const formSetCardContainer = document.querySelector<HTMLDivElement>(FORMSET_SELECTOR);
  if (!formSetCardContainer || !propertyForm) {
    return;
  }

  const formSetDefaultShow = formSetCardContainer.dataset.formsetDefaultShow || 'false';
  const isOptionList = isOptionListProperty(getCurrentPropertyTypeValue());
  const hideFormsetsByDefault = formSetDefaultShow.toLowerCase() === 'false' || !isOptionList;
  if (hideFormsetsByDefault) {
    initializeHiddenElements();
  }
  const isOptionListWithoutFilter = isOptionList && !getCurrentFilterPropertyValue();
  if (isOptionListWithoutFilter) {
    hideAllRelatedPropertiesRows();
  }

  initializeBaseChoiceInstance();
  initializePropertyTypeSelect();
  initializeAddOptionButton();
  initializeFilterRelatedPropertySelect();
  initializeRemoveOptionButton();
  createCurrentVisibleFormSetsHiddenInput();

  const isUpdatePropertyForm = propertyForm.classList.contains('update-property-form');

  if (isUpdatePropertyForm) {
    setInitialFilterRelatedPropertyOptions().catch((err) => {
      App.Utils.showError({
        message: err as string,
        type: 'danger',
        alertMessage: 'Oucrrió un error, por favor recargue la página.'
      });
    });
  }
});
