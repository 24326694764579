// Vendors
import Choices from 'choices.js';

// Utils
import { getParentBySelector } from '../utils/traversing';

export interface ChoicesReference {
  choicesRef: Choices | null;
  baseSelect: HTMLSelectElement;
  id: string;
}

interface ChoicesSearchEvent {
  detail: {
    value: string
    resultCount: number
  }
}

// Constants
export const GLOBAL_CHOICES_REFERENCES: ChoicesReference[] = [];

const CHOICES_LANG_TEXTS = {
  en: {
    loadingText: 'Loading...',
    noResultsText: 'No results found',
    noChoicesText: 'No choices to choose from',
    itemSelectText: '',
    addItemText: (value: string) => `Press Enter to add <b>"${value}"</b>`,
    maxItemText: (maxItemCount: number) => `Only ${maxItemCount} values can be added`,
    searchPlaceholderValue: 'Search'
  },
  es: {
    loadingText: 'Cargando...',
    noResultsText: 'No se encontraron resultados',
    noChoicesText: 'No hay opciones para elegir',
    itemSelectText: '',
    addItemText: (value: string) => `Pressione Enter para agregar <b>"${value}"</b>`,
    maxItemText: (maxItemCount: number) => `Sólo se pueden agregar ${maxItemCount} valores`,
    searchPlaceholderValue: 'Buscar'
  }
};

const SELECT_VALID_CLASS = 'is-valid';
const SELECT_INVALID_CLASS = 'is-invalid';

const CHOICES_VALID_CLASS = SELECT_VALID_CLASS;
const CHOICES_INVALID_CLASS = SELECT_INVALID_CLASS;

// Functions

/**
 * Search inside the current `GLOBAL_CHOICES_REFERENCES` and get the reference by given id
 * @param {string} id
 * @returns {ChoicesReference|undefined} The found choices reference
 */

export function getChoicesReferenceById(id: string) {
  const choicesReference = GLOBAL_CHOICES_REFERENCES.find(({ id: refId }) => id === refId);

  return choicesReference;
}
/**
 * Define if the placeholder should is visible to the user or not.
 * If `choices` has selected options, then hide it. Otherwise, show it.
 * @param {Choices} choices
 */
function setChoicesMultiplePlaceholderVisibility(choices: Choices) {
  const searchInput = choices.input.element;
  const hasSelectedOptions = (choices.getValue() as []).length !== 0;

  if (hasSelectedOptions) {
    searchInput.classList.add('choices__input--placeholder-hidden');
  } else {
    searchInput.classList.remove('choices__input--placeholder-hidden');
  }
}

/**
 * Generates a button to be appended inside a Choices instance. The button has an event
 * to create a new option inside the Choices when clicked
 * @param optionValue string - The value of the option to be created in the choicesInstance
 * @param choicesInstance Choices - The Choices element in which the option will be created
 * @returns HTMLButtonElement
 */
function generateCreateOptionButton(optionValue: string, choicesInstance: Choices) {
  const button = document.createElement('button');
  button.textContent = `Crear opción: ${optionValue}`;
  button.classList.add('btn', 'btn-new-option', 'btn-link');

  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  button.addEventListener('click', async () => {
    const newChoice = { value: optionValue, label: optionValue, isSelected: true };
    await choicesInstance.setChoices([newChoice]);
    choicesInstance.setChoiceByValue(optionValue);
    choicesInstance.clearInput();
    button.remove();
  });
  return button;
}

/**
 * Handles the search event in a Choices element to decide whether the button to
 * create a new option dynamically should be appended.
 * @param event ChoicesSearchEvent
 * @param choicesInstance Choices
 */
function handleChoicesSearch(event: ChoicesSearchEvent, choicesInstance: Choices) {
  const { detail } = event;
  const noResultsAvailable = detail.resultCount === 0;

  if (noResultsAvailable) {
    const choicesList = choicesInstance.choiceList.element;
    const firstListItem = choicesList?.children[0];
    const listIsVoid = firstListItem?.classList.contains('has-no-results');

    if (listIsVoid && firstListItem) {
      firstListItem.classList.add('p-0');

      const button = generateCreateOptionButton(detail.value, choicesInstance);

      firstListItem.textContent = '';
      firstListItem.appendChild(button);
    }
  }
}

/**
 * Initialize `select` element as a Choices
 * @returns {Choices}
 */
export function initChoices(select: HTMLSelectElement) {
  const documentLang = document.documentElement.lang as 'en' | 'es';
  const isMultiple = select.hasAttribute('multiple');
  const placeholder = select.querySelector('option[value=""]')?.textContent;
  const isCreatable = select.hasAttribute('data-creatable');
  const options = {
    ...(isCreatable ? {
      fuseOptions: {
        threshold: 0.0
      }
    } : {}),
    shouldSort: false,
    searchResultLimit: 100,
    removeItemButton: isMultiple,
    placeholderValue: '',
    allowHTML: false,
    callbackOnInit: function onInit() {
      const { containerOuter } = this as unknown as { containerOuter: { element: Element } };
      const choicesElement = containerOuter.element;
      const selectHasInvalidClass = select.classList.contains(SELECT_INVALID_CLASS);
      const selectHasValidClass = select.classList.contains(SELECT_VALID_CLASS);
      const selectIsInvalid = select.matches(':invalid');
      const selectIsValid = select.matches(':valid');
      const selectIsInValidatedElement = !!getParentBySelector(choicesElement, '.was-validated');

      if (selectHasInvalidClass || (selectIsInValidatedElement && selectIsInvalid)) {
        choicesElement.classList.add(CHOICES_INVALID_CLASS);
      } else if (selectHasValidClass || (selectIsInValidatedElement && selectIsValid)) {
        choicesElement.classList.add(CHOICES_VALID_CLASS);
      }

      if (selectIsInValidatedElement) {
        select.addEventListener('change', () => {
          if (select.matches(':invalid')) {
            choicesElement.classList.add(CHOICES_INVALID_CLASS);
            choicesElement.classList.remove(CHOICES_VALID_CLASS);
          } else {
            choicesElement.classList.add(CHOICES_VALID_CLASS);
            choicesElement.classList.remove(CHOICES_INVALID_CLASS);
          }
        });
      }
    },
    ...CHOICES_LANG_TEXTS[documentLang]
  };
  const reference: ChoicesReference = {
    baseSelect: <HTMLSelectElement>select.cloneNode(true),
    id: select.id,
    choicesRef: null
  };

  const choices = new Choices(select, options);
  reference.choicesRef = choices;

  // Hide placeholder if select has selected options
  if (isMultiple && placeholder) {
    setChoicesMultiplePlaceholderVisibility(choices);
    select.addEventListener('change', () => {
      setChoicesMultiplePlaceholderVisibility(choices);
    }, false);
  }

  if (isCreatable) {
    choices.passedElement.element.addEventListener('search', (e) => {
      const choicesEvent = e as unknown as ChoicesSearchEvent;
      handleChoicesSearch(choicesEvent, choices);
    });
  }

  GLOBAL_CHOICES_REFERENCES.push(reference);
  return choices;
}

// Initialize behavior
window.addEventListener('DOMContentLoaded', () => {
  const choicesSelects = document.querySelectorAll('select:not(.js-not-choices):not(#id_region):not(#id_commune)');

  choicesSelects.forEach((v) => initChoices(v as HTMLSelectElement));
});
