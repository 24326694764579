type TogglerStatus = 'show' | 'hide';

const setTogglerStatusText = (toggler: HTMLButtonElement, status: TogglerStatus) => {
  let textContainer = toggler.querySelector('.collapse-visibility-toggler-text');
  if (!textContainer) {
    textContainer = document.createElement('span');
    textContainer.classList.add('collapse-visibility-toggler-text');
    toggler.appendChild(textContainer);
  }

  if (status === 'show') {
    textContainer.textContent = 'Ocultar detalle';
  } else {
    textContainer.textContent = 'Mostrar detalle';
  }
};

const setTogglerStatusIcon = (toggler: HTMLButtonElement, status: TogglerStatus) => {
  let iconContainer = toggler.querySelector<HTMLElement>('.icon');
  if (!iconContainer) {
    iconContainer = document.createElement('span');
    iconContainer.classList.add('icon');
    toggler.appendChild(iconContainer);
  }
  iconContainer.classList.add('material-icons');
  iconContainer.setAttribute('aria-hidden', 'true');

  if (status === 'show') {
    iconContainer.innerText = 'visibility_off';
  } else {
    iconContainer.innerText = 'remove_red_eye';
  }
  return iconContainer;
};

const changeTogglerStatus = (toggler: HTMLButtonElement, status: TogglerStatus) => {
  setTogglerStatusText(toggler, status);
  setTogglerStatusIcon(toggler, status);
  if (status === 'show') {
    toggler.classList.add('show');
  } else {
    toggler.classList.remove('show');
  }
};

const initCollapseVisibilityToggler = (toggler: HTMLButtonElement) => {
  changeTogglerStatus(toggler, 'hide');
  toggler.addEventListener('click', (e) => {
    const button = e.currentTarget as HTMLButtonElement;
    if (button.classList.contains('collapsed')) {
      changeTogglerStatus(button, 'hide');
    } else {
      changeTogglerStatus(button, 'show');
    }
  });
};

window.addEventListener('DOMContentLoaded', () => {
  const collapseVisibilityTogglers = document.querySelectorAll<HTMLButtonElement>('.collapse-visibility-toggler');

  collapseVisibilityTogglers.forEach((toggler) => {
    initCollapseVisibilityToggler(toggler);
  });
});
