const REPORT_FORM_SELECTOR = '#report-form';
const FORM_CHECKBOXES_SELECTOR = `${REPORT_FORM_SELECTOR} table input[type="checkbox"]`;
const GENERATE_REPORT_BUTTON_SELECTOR = '#reportFormButton';
const SELECT_ALL_CHECKBOX = 'selectall';

const getReportFormTableCheckboxes = () => document
  .querySelectorAll<HTMLInputElement>(FORM_CHECKBOXES_SELECTOR);

const checkboxIsChecked = (checkbox: HTMLInputElement) => {
  const { id, checked } = checkbox;

  if (id !== SELECT_ALL_CHECKBOX) {
    return checked;
  }

  return false;
};

const setDisableStatusOfReportButton = (newStatus: boolean) => {
  const generateReportButton = document
    .querySelector<HTMLButtonElement>(GENERATE_REPORT_BUTTON_SELECTOR);
  if (!generateReportButton) return;

  generateReportButton.disabled = newStatus;
};

const setFeedbackMessage = (checkbox: HTMLInputElement, message: string) => {
  const table = checkbox.closest<HTMLTableElement>('table');
  const feedbackContainer = table?.querySelector<HTMLTableRowElement>('.js-table-check-counter');

  if (!feedbackContainer) return;
  feedbackContainer.textContent = message;
};

const appendLinksToFeedbackMessage = (
  container: HTMLTableRowElement, links: HTMLAnchorElement[]
) => {
  container.appendChild(links[0]);
  container.appendChild(links[1]);
};

const generateFeedbackLinks = (container: HTMLTableRowElement) => {
  const link = document.createElement('a');
  const link2 = document.createElement('a');
  const { pageElementsAmount } = container.dataset;
  const pageElementsSelectAnchorMessage = `Seleccionar sólo los ${pageElementsAmount || ''} documentos de esta página`;
  const pageElementsSelectedMessage = `Se seleccionaron los ${pageElementsAmount || ''} documentos de esta página`;

  link.href = '';
  link2.href = '';

  link.textContent = pageElementsSelectAnchorMessage;
  link2.textContent = 'Anular selección';

  link.classList.add('ms-1');
  link2.classList.add('d-none', 'ms-1');

  link.addEventListener('click', (e) => {
    e.preventDefault();
    link.classList.add('d-none');
    link2.classList.remove('d-none');
    const selectAllCheckbox = document.querySelector<HTMLInputElement>(`#${SELECT_ALL_CHECKBOX}`);
    if (selectAllCheckbox) {
      selectAllCheckbox.checked = false;
      setFeedbackMessage(selectAllCheckbox, pageElementsSelectedMessage);
      appendLinksToFeedbackMessage(container, [link, link2]);
    }
  });

  link2.addEventListener('click', (e) => {
    e.preventDefault();
    link2.classList.add('d-none');
    container.classList.add('d-none');
    const table = container.closest<HTMLTableElement>('table');

    if (!table) return;

    const tableCheckboxes = table.querySelectorAll<HTMLInputElement>('input[type="checkbox"');

    tableCheckboxes.forEach((_, index: number) => {
      tableCheckboxes[index].checked = false;
    });

    setDisableStatusOfReportButton(true);
  });
  return [link, link2];
};

const handleFeedbackMessage = (checkbox: HTMLInputElement) => {
  const table = checkbox.closest<HTMLTableElement>('table');
  const feedbackContainer = table?.querySelector<HTMLTableRowElement>('.js-table-check-counter');

  if (!feedbackContainer) return;
  feedbackContainer.classList.add('text-center');

  if (checkbox.checked) {
    setFeedbackMessage(checkbox, 'Se seleccionaron todos los documentos existentes');
    const links = generateFeedbackLinks(feedbackContainer);
    appendLinksToFeedbackMessage(feedbackContainer, links);
    feedbackContainer.classList.remove('d-none');
  } else {
    feedbackContainer.innerHTML = '';
    feedbackContainer.classList.add('d-none');
  }
};

const handleReportTableCheckboxChange = (checkbox: HTMLInputElement) => {
  const { id, checked } = checkbox;

  if (id === SELECT_ALL_CHECKBOX) {
    setDisableStatusOfReportButton(!checked);
    handleFeedbackMessage(checkbox);
    return;
  }

  const selectAllCheckbox = document.querySelector<HTMLInputElement>(`#${SELECT_ALL_CHECKBOX}`);
  if (selectAllCheckbox) {
    selectAllCheckbox.checked = false;
  }

  const table = checkbox.closest<HTMLTableElement>('table');
  const feedbackContainer = table?.querySelector<HTMLTableRowElement>('.js-table-check-counter');

  if (feedbackContainer) {
    feedbackContainer.classList.add('d-none');
  }

  const allCheckboxes = [...getReportFormTableCheckboxes()];
  setDisableStatusOfReportButton(!allCheckboxes.some(checkboxIsChecked));
};

window.addEventListener('DOMContentLoaded', () => {
  const generateReportForm = document.querySelector<HTMLFormElement>(REPORT_FORM_SELECTOR);
  const formCheckBoxes = getReportFormTableCheckboxes();

  if (!generateReportForm || !formCheckBoxes) return;

  formCheckBoxes.forEach((checkbox) => checkbox.addEventListener('change', () => handleReportTableCheckboxChange(checkbox)));
});
