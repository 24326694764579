type CorrelativeData = {
  correlative: string
};

const setRealNumberInputValue = (value: string) => {
  const realNumberInput = document.querySelector<HTMLInputElement>('#id_real_number');
  if (realNumberInput) {
    realNumberInput.value = value;
  }
};

const handleCorrelativeResponseData = (data: CorrelativeData) => {
  if (data.correlative.trim()) {
    setRealNumberInputValue(data.correlative);
  }
};

const handleDocumentTypeChange = (e: Event) => {
  const select = e.target as HTMLSelectElement;

  if (!select.value) {
    setRealNumberInputValue('');
    return;
  }
  // Type assertion beacause we know the data attribute exists
  const projectId = select.dataset.projectId!;
  fetch(`/concessions/next-correlative?document_kind=outgoing&document_type=${select.value}&project=${projectId}`)
    .then((res) => res.json())
    .then(handleCorrelativeResponseData)
    .catch((err) => console.log(err)); // TODO: Add alert error handling
};

window.addEventListener('DOMContentLoaded', () => {
  const documentTypeSelect = document.querySelector<HTMLSelectElement>('#id_document_type[data-project-id]');

  if (documentTypeSelect) {
    documentTypeSelect.addEventListener('change', handleDocumentTypeChange);
  }
});
